"use client";

import { Y, Frame, X, Text } from "@reframe.so/blocks";
import { ThreadConversation } from "@src/app/(app)/chat/[id]/thread/client/conversation";
import { useState, useMemo, useEffect } from "react";

const postMessage = (type: string, data: unknown) => {
  if (window.parent !== window) {
    window.parent.postMessage(
      {
        type,
        data,
      },
      "*"
    );
  }
};

export const ChatWidget = () => {
  const [mobile, setMobile] = useState(false);
  const widget = useMemo(
    () => ({
      mobile,
      onClose: () => {
        postMessage("close", null);
      },
      onReset: () => {
        postMessage("reset", null);
        const url = new URL(window.location.href);
        url.searchParams.delete("thread");
        url.searchParams.set("reset", "true");
        window.location.href = url.toString();
      },
    }),
    [mobile]
  );

  useEffect(() => {
    const listener = (event: MessageEvent<any>) => {
      console.log("[frame/inner] received message", event.data);
      switch (event.data.type) {
        case "mobile":
          setMobile(event.data.data);
          break;
        default:
          break;
      }
    };

    postMessage("ready", null);
    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  return <ThreadConversation widget={widget} />;
};

export default function NotFoundChatWidget({ message }: { message?: string }) {
  return (
    <Y css="p-0 gap-1 relative grow">
      <Frame
        direction="x"
        css={[
          "h-16 text-primary-50 text-lg font-normal tracking-tight items-center justify-center gap-2 bg-primary-400",
        ]}
      >
        <Text className="">Warning!</Text>
      </Frame>

      <Y css="min-h-[212px] grow">
        <Y data-role="chat-container" css="grow gap-3 pt-4">
          <X
            data-role="chat-scroll-anchor"
            css="h-px bg-transparent mt-2 [overflow-anchor:auto]"
          />
          <Text css="text-center font-semibold px-5">
            {message ?? " Something went wrong. Please try again later."}
          </Text>
        </Y>
      </Y>
      <X>
        <Text className="w-full px-5 pb-16 text-center text-sm text-gray-500">
          Experiencing difficulties? Reach out to our support team for
          assistance and guidance.
        </Text>
      </X>
    </Y>
  );
}
